import self from '../img/dp.jpg';
import { colors } from '../colors/mainGradient';

export const info = {
  firstName: 'Srimal',
  lastName: 'Fernando',
  initials: 'SF', // the example uses first and last, but feel free to use three or more if you like.
  position: 'a designer and a student',
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: '🇱🇰',
      text: 'based in Colombo, Sri Lanka',
    },
    {
      emoji: '🎨👨‍💻📱',
      text: 'Computer Science Undergraduate',
    },
    {
      emoji: '📧',
      text: 'Srimal.cyber@gmail.com',
    },
  ],
  socials: [
    {
      link: 'https://srimal.me/resume.pdf', // this should be https://yourname.com/resume.pdf once you've deployed
      icon: 'fa fa-user-circle',
      label: 'resume',
    },
    {
      link: 'https://instagram.com/srimal.online',
      icon: 'fa fa-instagram',
      label: 'instagram',
    },
    {
      link: 'https://github.com/srimalonline',
      icon: 'fa fa-github',
      label: 'github',
    },
    {
      link: 'https://www.linkedin.com/in/srimal-online/',
      icon: 'fa fa-linkedin',
      label: 'linkedin',
    },
  ],
  bio: "Hi there! I'm a Computer Science Undergraduate from the Milky Way,I am convinced of technology's transformative power. My commitment to personal and professional growth drives me to stay ahead of the curve by continually acquiring new skills and exploring emerging technologies.My passion for STEAM and drive for positive impact has led me to become a Content Creator, Vlogger, and Podcast Host. Through sharing my knowledge and experiences, I aim to inspire others to pursue their passions in the tech industry. As an active IEEE and AIESEC member, I foster meaningful connections and collaborate with like-minded individuals to drive innovation and change.",
  skills: {
    tools: ['Figma', 'Adobe Illustrator', 'Adobe XD', 'Photoshop', 'Webflow'],
    others: ['Basic HTML/CSS', 'User Reasearch', 'Usability Testing']
  },
  hobbies: [
    {
      label: 'Videography',
      emoji: '📷',
    },
    {
      label: 'Video games',
      emoji: '🎮',
    },
    {
      label: 'Guitar',
      emoji: '🎸',
    },
    {
      label: 'Movies',
      emoji: '🎞',
    },
  ],
  portfolio: [
    // This is where your portfolio projects will be detailed
    //   for the null ones, the button will not show up
    {
      title: 'Portfolio redesign',
      live: 'https://srimal.me/', //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
      source: 'https://github.com/srimalonline/srimal.me', // this should be a link to the **repository** of the project, where the code is hosted.
      image: 'https://srimal.com/website.jpg',
    }
  ],
};
